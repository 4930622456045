<template>
    <v-responsive>
        <v-container>
            <v-row v-if="expenses">
                <v-col>
                    <span>
                        Expenses (last two months):
                    </span>
                </v-col>
            </v-row>
            <v-row v-if="expenses">
                <v-col>
                    <v-data-table responsive :items="expenses" :headers="columns">

                        <template v-slot:[`item.RegisteredMillis`]="{ item }">
                            {{ formatDate(item.RegisteredMillis) }}
                        </template>

                        <template v-slot:[`item.AmountCop`]="{ item }">
                            {{ formatMoney(item.AmountCop) }}
                        </template>

                        <template v-slot:[`item.Description`]="{ item }">
                            {{ formatDescription(item.Description) }}
                        </template>

                        <template v-slot:[`item.Actions`]="{ item }">
                            <v-btn @click="deleteExpense(item)" style="cursor: pointer" icon="mdi-delete"></v-btn>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
            <v-row v-else>
                <span>
                    No expenses registered yet.
                </span>
            </v-row>
        </v-container>
    </v-responsive>
</template>
<script>
import store from '@/store';
import axios from 'axios';
import session from '@/session.js'
import moment from 'moment-timezone';
export default {
    name: "ExpensesSection",

    data() {
        return {
            columns: [
                { value: 'Actions', title: 'Actions' },
                { value: 'Description', title: 'Description' },
                { value: 'AmountCop', title: 'Amount' },
                { value: 'RegisteredMillis', title: 'Day' },
                { value: 'Issuer', title: 'Issuer' },
                { value: 'PaymentMethodID', title: 'Method' },
                { value: "GroupName", title:"Group"},
                
            ],
            USDollar: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            selectedExpense: {}
        }
    },
    methods: {
        formatDate(millis) {
            let d = moment.tz(millis, 'UTC')
            return d.format("MMMDD HH:mm")
        },
        formatMoney(price) {
            return this.USDollar.format(price)

        },
        formatDescription(str) {
            if (str) {
                if (str.length > 1) {
                    str = str.toLowerCase().trim()
                    str = str.charAt(0).toUpperCase() + str.slice(1)

                    if (str.length > 30) {
                        return str.substring(0, 15) + "..." + str.substring(str.length - 15, str.length)
                    }

                    return str
                }
            } else
                return "-"
        },
        deleteExpense(exp) {
            console.log(exp)
            axios.post("/api/del-expense", {},
                {
                    auth: {
                        username: session.gsd("uname"),
                        password: session.gsd("passwd")
                    },
                    headers: {
                        expenseId: exp.ID
                    }
                }).then(resp => {
                    session.ssd('meta-data', JSON.stringify(resp.data))
                    store.commit('loadMetadata')
                })
        }
    },
    computed: {
        expenses() {
            if (store.getters.Expenses && store.getters.Expenses.length > 0)
                return store.getters.Expenses
            else return null
        }
    }
}
</script>